import './HomeComp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import productsJson from '../../../../assets/data/products.json';

import HeaderComp from '../../common/header/HeaderComp';
import PageBannerComp from '../../common/page-banner/PageBannerComp';
import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import DefaultSectionHeadingComp from '../../common/default-section-components/DefaultSectionHeadingComp';
import DefaultSectionBodyComp from '../../common/default-section-components/DefaultSectionBodyComp';
import FooterComp from '../../common/footer/FooterComp';
import { IMAGE_URL } from '../../../../config/config';



const HomeComp = () => {
    return (
        <>
            <HeaderComp />
            <PageBannerComp className={'page-banner-comp'} bType="slider" bDetails={productsJson} />
            <DefaultSectionComp className="section light bg-img home-section-about" customInlineStyles={{backgroundImage:`linear-gradient(45deg, rgba(var(--white-rgb) / 95%), rgba(var(--white-rgb) / 95%)), url(${IMAGE_URL}images/bg.jpg)`, backgroundAttachment:'fixed', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
                <div className='container'>
                    <DefaultSectionHeadingComp className="text-center position-relative">
                        <h2>About Vnext</h2>
                        <p>A Short Story About Our Journey</p>
                    </DefaultSectionHeadingComp>
                    <DefaultSectionBodyComp className={"text-center"}>
                        <p>Visaka Industries was founded in 1981, thanks to Dr G Vivekanand's perseverance, and has since then grown into the 2nd largest manufacturer of Fiber Cement Roofing Sheets.With a turnover of over 1000 crores, Vnext is the Next Big Thing in the Construction Industry.</p>
                        <p>Vnext Products come with unmatched quality, aesthetics, style, and durability, making them the ideal choice for smart and good looking interiors and exteriors. Being fire, water, and termite resistant makes them the perfect choice for long-lasting, sturdy, and hazard-free construction.</p>
                        <p>Vnext products have external and internal applications and are an excellent choice for panelling, cladding, false ceilings, partitions, mezzanine flooring, and much more.</p>
                    </DefaultSectionBodyComp>
                </div>
            </DefaultSectionComp>
            <DefaultSectionComp className="section home-section-blank-bg" customInlineStyles={{backgroundImage:`url(${IMAGE_URL}images/bg.jpg)`}}></DefaultSectionComp>
            <DefaultSectionComp className="section light bg-img home-section-usps" customInlineStyles={{backgroundImage:`linear-gradient(45deg, rgba(var(--white-rgb) / 95%), rgba(var(--white-rgb) / 95%)), url(${IMAGE_URL}images/bg.jpg)`, backgroundAttachment:'fixed', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
                <div className='container'>
                    <DefaultSectionHeadingComp className="text-center position-relative">
                        <h2>An <b className='color-green'>Eco-Friendly</b> Alternative to Plywood</h2>
                        <p>Our products are</p>
                    </DefaultSectionHeadingComp>
                    <DefaultSectionBodyComp className={"text-center"}>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-3'>
                                <div className='home-section-usp-item w-100 mb-4'>
                                    <div className='icon mb-2'><img src={IMAGE_URL+'images/fire.png'} alt='Fire Resistant' /></div>
                                    <div className='title'>Fire&nbsp;&nbsp;Resistant</div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3'>
                                <div className='home-section-usp-item w-100 mb-4'>
                                    <div className='icon mb-2'><img src={IMAGE_URL+'images/water.png'} alt='Water Resistant' /></div>
                                    <div className='title'>Water&nbsp;&nbsp;Resistant</div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3'>
                                <div className='home-section-usp-item w-100 mb-4'>
                                    <div className='icon mb-2'><img src={IMAGE_URL+'images/terminate.png'} alt='Termite Resistant' /></div>
                                    <div className='title'>Termite&nbsp;&nbsp;Resistant</div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3'>
                                <div className='home-section-usp-item w-100 mb-4'>
                                    <div className='icon mb-2'><img src={IMAGE_URL+'images/eco.png'} alt='Eco Friendly' /></div>
                                    <div className='title'>Eco&nbsp;&nbsp;Friendly</div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <a href='/products' className='btn position-relative overflow-hidden d-block me-0 mb-3 d-sm-inline-block mb-sm-0 me-sm-3'>View Products</a>
                                <a href='/certificates' className='btn yellow position-relative overflow-hidden d-block d-sm-inline-block'>View Certificates</a>
                            </div>
                        </div>
                    </DefaultSectionBodyComp>
                </div>
            </DefaultSectionComp>
            <DefaultSectionComp className="section dark bg-img home-section-applications" customInlineStyles={{backgroundImage:`linear-gradient(45deg, rgba(var(--black-rgb) / 95%), rgba(var(--black-rgb) / 95%)), url(${IMAGE_URL}images/bg.jpg)`, backgroundAttachment:'fixed', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
                    <div className='container'>
                        <DefaultSectionHeadingComp className="text-center position-relative">
                            <h2>Residential & Commercial Solutions</h2>
                            <p>Brilliant, Modern and Utilitarian</p>
                        </DefaultSectionHeadingComp>
                        <DefaultSectionBodyComp className={"text-center"}>
                            <div className='row'>
                                <div className='col-12 col-lg-5 order-lg-1'>
                                    <div className='home-section-applications-img' style={{backgroundImage:`url(${IMAGE_URL+'images/residential.jpg'})`}}></div>
                                </div>
                                <div className='col-12 col-lg-7 d-lg-flex align-items-center'>
                                    <div className='home-section-applications-content pt-4 pt-lg-0 text-lg-start'>
                                        <p>We have a wide range of products that can accommodate your unique residential and commercial needs Vnext products can be used for various applications like panelling, cladding, false ceilings, partitions, and mezzanine flooring with multiple texture options. The plain variants can be finished with paint, laminate, wall, paper, veneer or tiles.</p>
                                        <p>The vast variety in our product line and our brilliant, modern utilitarian perspective is reflected in our products' diversity.</p>
                                        <a href='/solutions' className='btn position-relative overflow-hidden'>Know more</a>
                                    </div>
                                </div>
                            </div>
                        </DefaultSectionBodyComp>
                </div>
            </DefaultSectionComp>
            <DefaultSectionComp className="section bg-img home-section-join-hands" customInlineStyles={{backgroundImage:`url(${IMAGE_URL}images/bg2.jpg)`}}>
                <div className='container pb-5 mb-5'>
                    <div className="text-center home-section-join-hands-content pb-5 mb-5">
                        <h2 className='title'>JOIN HANDS IN BUILDING<br/>AN <b className='color-green'>ECO-FRIENDLY</b> FUTURE</h2>
                        <p className='desc mb-5 pb-5'><q>SMALL ACTS, WHEN MULTIPLIED BY MILLIONS OF PEOPLE, CAN TRANSFORM THE WORLD.</q></p>
                    </div>
                </div>
            </DefaultSectionComp>
            <FooterComp />
        </>
    );
}
export default HomeComp;