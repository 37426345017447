import react from 'react';
import { Link } from 'react-router-dom';

import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import SailentFeaturesComp from '../salient-features/SailentFeaturesComp';
import { IMAGE_URL } from '../../../../config/config';

const ProductDetailsComp = ({ pDetails }) => {

    const convertJSX = htmlData => {
        return htmlData.map((h, hIndex)=>{
            let attributeObj = {};
            const {attributes, localName, childNodes, nodeValue} = h;
            if(attributes){
                Array.from(attributes).forEach(attribute => {
                    if(attribute.name === "style"){
                        let styleAttributes = attribute.nodeValue.split(";");
                        let styleObj = {};
                        styleAttributes.forEach(attribute => {
                            let [key, value] = attribute.split(":");
                            styleObj[key] = value;
                        });
                        attributeObj[attribute.name] = styleObj;
                    }
                    else if(attribute.name === 'class'){ attributeObj['className'] = attribute.nodeValue; }
                    else { attributeObj[attribute.name] = attribute.nodeValue; }
                });
            }
            attributeObj['key'] = hIndex;
            return localName ? react.createElement(localName, attributeObj, childNodes && Array.isArray(Array.from(childNodes)) ? convertJSX(Array.from(childNodes)) : []) : nodeValue;
        });
    }

    const carouselIndicators = pDetails.images?.map((_, index) => ( <button key={index} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current={index === 0 ? 'true' : undefined} aria-label={`Slide ${index + 1}`}></button> ));
    
    const productSlider = pDetails.images?.map((c, index) => {
        const isActive = index === 0;
        const imageUrl = `${IMAGE_URL}${c}`;
        return (<div className={`carousel-item ${isActive ? 'active' : ''}`} key={index}><img src={imageUrl} className="d-block w-100 rounded shadow" alt={`Slide ${index + 1}`} /></div>);
    });

    return (<>
        <DefaultSectionComp className="product-details">
            <div className="container">
                <div className="row content align-items-center">
                    <div className="col-md-4">
                        <div className='image'><img src={`${IMAGE_URL}${pDetails.product_image}`} className='img-fluid w-75 mx-auto d-block' alt=""/></div>
                    </div>
                    <div className="col-md-8">
                        <div className='desc drop_caps_1'><p className='lh-lg mb-0'>{pDetails.content.desc}</p></div>
                    </div>
                </div>
            </div>
        </DefaultSectionComp>
        <DefaultSectionComp className="light bg-img product-details-list">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className='row gy-3'>
                            {pDetails.content.list.map((l, lIndex) => {
                                let descData = new DOMParser().parseFromString(l.desc, 'text/html').body.childNodes;
                                return  <div key={lIndex} className="col-12 col-md-6 col-lg-6"><div className="title"><h5 className='mb-2'>{l.title}</h5></div><div className="desc lh-lg">{convertJSX(Array.from(descData))}</div></div>
                            })}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="image"><img className="w-100 img-fluid" src={`${IMAGE_URL}${pDetails.images ? 'd-block':'d-none'}`} alt="" /></div>
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">{carouselIndicators}</div>
                            <div className="carousel-inner">{productSlider}</div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='d-block d-xl-flex justify-content-center view-all text-center mt-5 mt-lg-4 mt-xl-4 gap-2'>
                            <a href={`${IMAGE_URL}${pDetails.broucher}`} className="btn yellow mb-3 mb-lg-0 mb-xl-0" target='_blank' rel="noopener noreferrer">Download Brochure</a>
                            <Link to={'/products'} className="btn btn-default text-capitalize" type="button"> View All Products</Link>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultSectionComp>
        <SailentFeaturesComp/>
    </>);
}

export default ProductDetailsComp;