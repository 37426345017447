import React from 'react'
import '../../landing-pages/vnext-solutions/VnextSolutionsComp.css';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../../config/config';
import VnextSolutionsFormComp from './VnextSolutionsFormComp';

const VnextSolutionsComp = () => {
    const backgroundImageUrl = `${IMAGE_URL}images/vnext-solutions/banner/vnext-solutions-hero.jpg`;

    const scrollToAboutSection = () => {
        document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
    }
    
    const scrollToProductSection = () => {
        document.getElementById('products').scrollIntoView({ behavior: 'smooth' });
    }
    
    return (
        <>
            <div className='vnext-solutions-wrapper'>
                <section className='vs-slider position-relative vh-100 text-center text-lg-start text-xl-start text-xxl-start position-relative' style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                    <div className='container'>
                        <div className='d-block d-lg-flex d-xl-flex d-xxl-flex align-items-center justify-content-start vh-100 vh-auto'>
                            <div className='col-12 col-lg-6 col-xl-6 col-xxl-6 px-3 px-lg-0 py-3 py-lg-0 py-xl-0 py-xxl-0 mb-xl-0 mb-xxl-0'>
                                <div className='logo'>
                                    <img src={IMAGE_URL + 'images/vnext-solutions/logo.png'} className="mb-4" alt='' />
                                </div>
                                <div className='content'>
                                    <h1>Sustainable</h1>
                                    <p>Imagine a new home, office or factory without environmental damage? It's time to rethink building with Vnext. Using our eco-friendly Vnext range, with every 140 sq.m of construction, you save 6 trees and reduce up to 1.3 Tons worth of carbon emissions! Sign up for sustainable housing solutions today. Together, let's build the future.</p>
                                </div>
                                <div className='buttons'>
                                    <div className='d-flex gap-3 justify-content-center justify-content-lg-start'>
                                        <div>
                                            <button className='btn rounded-0' onClick={scrollToAboutSection}>Know More</button>
                                        </div>
                                        <div>
                                            <button className='btn rounded-0' onClick={scrollToProductSection}>Our Products</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='vs-form position-absolute top-50 end-0'>
                            <div className='enquiryForm bg-white rounded'>
                                <VnextSolutionsFormComp />
                            </div>
                        </div>
                        <div className="s01_next" onClick={scrollToAboutSection}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#fff"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3 .1-17z"/></svg>
                        </div>
                    </div>
                </section>
                <section className='vs-about py-5 my-5' id="about">
                    <div className='container'>
                        <div className='d-block d-lg-flex d-xl-flex d-xxl-flex align-items-center gap-5'>
                            <div className='col-12 col-lg-4 col-xl-4 mb-5 mb-xl-0 mb-xxl-0'>
                                <div className='img'>
                                    <img src={IMAGE_URL + 'images/vnext-solutions/sustainable-globe.png'} className="img-fluid" alt='' />
                                </div>
                            </div>
                            <div className='col-12 col-lg-8 col-xl-8'>
                                <div className='content'>
                                    <h1 className="lh-base"><sup className="pe-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z" /></svg></sup>Join <br />hands in building<br />A<span className="mx-3">sustainable</span>future<sup className="ps-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z" /></svg></sup></h1>
                                    <p>We at Vnext believe that our resources at earth are limited and are working towards building a greener tomorrow. Formulated with fire, water and termite resistant qualities, our sustainable range of fibre cement boards come with unmatched quality, aesthetics, style and durability. The ideal choice that stands the test of time, construction made of Vnext is built to last for over 50 years!</p>
                                    <p>Used for external and internal applications like panelling, cladding, false ceilings, partitions, mezzanine flooring, the sky is the limit to build sturdy, hazard-free and long-lasting living spaces. Choose from our extensive range of eco-friendly products for construction that is lighter on the planet.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="vs-products py-5" id="products">
                    <div className="container">
                        <div className="row">
                            <div className="heading">
                                <h2 className="mb-3">our<br />extensive<span className="ps-3">range</span></h2>
                                <p>A versatile, affordable and sustainable building material range, Vnext supports various interior and exterior grade applications such as wall panelling, duct covering, false ceiling, mezzanine flooring, pre-fab construction, internal wall panelling and external walls.The unique surfaces of Vnext can be customized using paint, PU coating or polish to attain the perfect finish.</p>
                            </div>
                            <ul className="nav nav-tabs justify-content-start justify-content-lg-end justify-content-xl-end justify-content-xxl-end" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" className='me-2'><path d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"/></svg>Vboards</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" className='me-2'><path d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"/></svg>Vpanels</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="third-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" className='me-2'><path d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"/></svg>Vpremium Boards</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="fourth-tab" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" className='me-2'><path d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"/></svg>Vplanks</button>
                                </li>
                            </ul>
                            <div className="tab-content bg-white py-5">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className='row d-block d-lg-flex d-xl-flex d-xxl-flex align-items-center mb-3'>
                                        <div className='col-12 col-lg-3 col-xl-3 col-xxl-3 text-center'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vboard.png'} className="img-fluid" alt='' />
                                        </div>
                                        <div className='col-12 col-lg-9 col-xl-9 col-xxl-9 p-3 p-xl-5 p-xxl-5 border-start border-none'>
                                            <div className='heading mb-3'>
                                                <h3>Vnext Boards</h3>
                                                <h4>Fiber Cement Board</h4>
                                            </div>
                                            <div className="content">
                                                <p className='mb-4'>A flexible and aesthetically appealing product, Vnext Board is suitable for the applications such as building storage units, wall panelling, false ceiling, mezzanine flooring and wall partitions. Available in varied thicknesses, Vnext Boards are crafted withstand defined stresses and loads from light to heavy applications. Vnext Boards also offer fire resistance along with acoustic and thermal insulation.</p>
                                                <h4 className='mb-3'>Applications</h4>
                                                <ul>
                                                    <li>Fire-rated Acoustic Partitions</li>
                                                    <li>Acoustic Partitions</li>
                                                    <li>Wall Panelling</li>
                                                    <li>Grid False Ceiling</li>
                                                    <li>Seamless False Ceiling</li>
                                                    <li>Mezzanine Flooring</li>
                                                    <li>Kitchen Cabinets & Shelves</li>
                                                    <li>Wardrobes</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-block d-lg-flex d-md-flex d-xl-flex d-xxl-flex'>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vboard/1.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vboard/2.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vboard/3.jpg'} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className='row d-block d-lg-flex d-xl-flex d-xxl-flex align-items-center'>
                                        <div className='col-12 col-lg-3 col-xl-3 col-xxl-3 text-center'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpanel.png'} className="img-fluid" alt='' />
                                        </div>
                                        <div className='col-12 col-lg-9 col-xl-9 col-xxl-9 p-3 p-xl-5 p-xxl-5 border-start'>
                                            <div className='heading mb-3'>
                                                <h3>Vnext Panels</h3>
                                                <h4>Instant dry wall</h4>
                                            </div>
                                            <div className="content">
                                                <p className='mb-4'>Preferred product for both external and internal applications such as pre-fab structures, interior partitions and exterior walls, Vnext Panels are an excellent choice a for camps, site offices, godowns, villas, resorts and other infrastructural site facilities. Extremely portable, these Vnext buildings can also be customized for dismount and relocation.</p>
                                                <p>Slender and stylish, partitions made of Vnext Panels leave a larger carpet area for construction in commercial complexes.</p>
                                                <h4 className='mb-3'>Applications</h4>
                                                <ul>
                                                    <li>Pre Fabricated Structures</li>
                                                    <li>Internal Partitions</li>
                                                    <li>External Walls</li>
                                                    <li>Compund Walls</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-block d-lg-flex d-md-flex d-xl-flex d-xxl-flex'>
                                        <div className='col-12 col-md-4 col-lg-flex col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpanel/1.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-flex col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpanel/2.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-flex col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpanel/3.jpg'} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className='row d-block d-lg-flex d-xl-flex d-xxl-flex align-items-center'>
                                        <div className='col-12 col-lg-3 col-xl-3 col-xxl-3 text-center'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpremium.png'} className="img-fluid" alt='' />
                                        </div>
                                        <div className='col-12 col-lg-9 col-xl-9 col-xxl-9 p-3 p-xl-5 p-xxl-5 border-start'>
                                            <div className='heading mb-3'>
                                                <h3>Vnext Premium Boards</h3>
                                                <h4>Heavy duty board</h4>
                                            </div>
                                            <div className="content">
                                                <p className='mb-4'>Vnext Premium Boards are exterior-grade fibre cement boards that are suitable for popular applications such as duct covering, roof underlay, soffit covering, external siding and wet area cladding.</p>
                                                <p>Our boards are also available in textured surface finishes— Vnext Cedar, Vnext Stone, Vnext Wave, Vnext Sandstone, Vnext Oceanic and Vnext L&H— as ready to use wall panel</p>
                                                <h4 className='mb-3'>Applications</h4>
                                                <ul>
                                                    <li>External Cladding</li>
                                                    <li>CNC Cutting</li>
                                                    <li>Roof Underlay</li>
                                                    <li>Duct Covering</li>
                                                    <li>Wet Area Applications</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-block d-lg-flex d-md-flex d-xl-flex d-xxl-flex'>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpremium/1.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpremium/2.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vpremium/3.jpg'} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className='row d-block d-lg-flex d-xl-flex d-xxl-flex align-items-center'>
                                        <div className='col-12 col-lg-3 col-xl-3 col-xxl-3 text-center'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vplank.png'} className="img-fluid" alt='' />
                                        </div>
                                        <div className='col-12 col-lg-9 col-xl-9 col-xxl-9 p-3 p-xl-5 p-xxl-5 border-start'>
                                            <div className='heading mb-3'>
                                                <h3>Vnext Premium Planks</h3>
                                                <h4>Tough inside, beautiful outside</h4>
                                            </div>
                                            <div className="content">
                                                <p className='mb-4'>Vnext Premium planks are Manufactured as per IS 14862:2000 Type "A" category standards, Vnext Premium Planks bring to life the beauty of wood grain without damage to the planet. Liven up outdoor spaces with added aesthetics and durability with rich-coloured finishes— Rosewood and Nut Brown.</p>
                                                <p>Used for external and internal cladding, soffit covering, duct covering, eaves lining and fencing, Vnext Premium Planks are a popular choice for exterior grade applications.</p>
                                                <h4 className='mb-3'>Applications</h4>
                                                <ul>
                                                    <li>External Cladding</li>
                                                    <li>Facade</li>
                                                    <li>Duct Covering</li>
                                                    <li>Compound Wall Cladding</li>
                                                    <li>Garden Fencing</li>
                                                    <li>Gate Fencing</li>
                                                    <li>Compound Wall Extension</li>
                                                    <li>Soffit</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-block d-lg-flex d-md-flex d-xl-flex d-xxl-flex'>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vplank/1.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vplank/2.jpg'} className='img-fluid' alt="" />
                                        </div>
                                        <div className='col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/products/vplank/3.jpg'} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='vs-advantages py-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='heading text-center mb-5'>
                                <h2>advantages of</h2>
                                <h3>vnext?</h3>
                            </div>
                            <div className='content'>
                                <div className='row d-flex'>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-4'>
                                        <div className='box text-center py-3 h-100'>
                                            <div className='img'>
                                                <img src={IMAGE_URL + 'images/vnext-solutions/advantages/green-building.png'} className='img-fluld mx-auto d-block mb-2' alt='' />
                                            </div>
                                            <div className='title'>
                                                <p className='mb-0'>Green building material</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-4'>
                                        <div className='box text-center py-3 h-100'>
                                            <div className='img'>
                                                <img src={IMAGE_URL + 'images/vnext-solutions/advantages/durable.png'} className='img-fluld mx-auto d-block mb-2' alt='' />
                                            </div>
                                            <div className='title'>
                                                <p className='mb-0'>Durable & long lasting</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-4'>
                                        <div className='box text-center py-3 h-100'>
                                            <div className='img'>
                                                <img src={IMAGE_URL + 'images/vnext-solutions/advantages/fire-terminate.png'} className='img-fluld mx-auto d-block mb-2' alt='' />
                                            </div>
                                            <div className='title'>
                                                <p className='mb-0'>Water, termite & fire-resistant</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-4'>
                                        <div className='box text-center py-3 h-100'>
                                            <div className='img'>
                                                <img src={IMAGE_URL + 'images/vnext-solutions/advantages/reusable.png'} className='img-fluld mx-auto d-block mb-2' alt='' />
                                            </div>
                                            <div className='title'>
                                                <p className='mb-0'>Reusable & relocatable</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-4'>
                                        <div className='box text-center py-3 h-100'>
                                            <div className='img'>
                                                <img src={IMAGE_URL + 'images/vnext-solutions/advantages/labour.png'} className='img-fluld mx-auto d-block mb-2' alt='' />
                                            </div>
                                            <div className='title'>
                                                <p className='mb-0'>Reduces labour costs</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-4'>
                                        <div className='box text-center py-3 h-100'>
                                            <div className='img'>
                                                <img src={IMAGE_URL + 'images/vnext-solutions/advantages/4x-faster.png'} className='img-fluld mx-auto d-block mb-2' alt='' />
                                            </div>
                                            <div className='title'>
                                                <p className='mb-0'>4x faster construction</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='vs-certifications py-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='heading text-center mb-5'>
                                <h2>awards <span>&</span> certifications</h2>
                            </div>
                            <div className='content'>
                                <div className='row d-flex justify-content-center'>
                                    <div className='col-6 col-lg-4 col-xl-3 col-xxl-3'>
                                        <div className='box shadow py-3 py-xl-3 py-xxl-3 rounded mb-4'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/certificates/green-pro.jpg'} className='img-fluid mx-auto d-block mb-3' alt="" />
                                            <p className='mb-0 text-uppercase text-center fw-bold'>green pro</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 col-xxl-3'>
                                        <div className='box shadow py-3 py-xl-3 py-xxl-3 rounded mb-4'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/certificates/exova.jpg'} className='img-fluid mx-auto d-block mb-3' alt="" />
                                            <p className='mb-0 text-uppercase text-center fw-bold'>exova</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 col-xxl-3'>
                                        <div className='box shadow py-3 py-xl-3 py-xxl-3 rounded mb-4'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/certificates/qai.jpg'} className='img-fluid mx-auto d-block mb-3' alt="" />
                                            <p className='mb-0 text-uppercase text-center fw-bold'>qai</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 col-xxl-3'>
                                        <div className='box shadow py-3 py-xl-3 py-xxl-3 rounded mb-4'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/certificates/tuv.jpg'} className='img-fluid mx-auto d-block mb-3' alt="" />
                                            <p className='mb-0 text-uppercase text-center fw-bold'>tuv</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 col-xxl-3'>
                                        <div className='box shadow py-3 py-xl-3 py-xxl-3 rounded mb-4'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/certificates/wtf.jpg'} className='img-fluid mx-auto d-block mb-3' alt="" />
                                            <p className='mb-0 text-uppercase text-center fw-bold'>wtf</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 col-xxl-3'>
                                        <div className='box shadow py-3 py-xl-3 py-xxl-3 rounded mb-4'>
                                            <img src={IMAGE_URL + 'images/vnext-solutions/certificates/cnbc_award.png'} className='img-fluid mx-auto d-block mb-3' alt="" />
                                            <p className='mb-0 text-uppercase text-center fw-bold'>cnbc</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className='py-4'>
                    <p className='text-center text-white mb-0'>© 2024 <Link to="/"> Vnext by Visaka</Link></p>
                </footer>
            </div>
        </>
    )
}

export default VnextSolutionsComp