import React from 'react'
import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp'
import DefaultSectionHeadingComp from '../../common/default-section-components/DefaultSectionHeadingComp'
import DefaultSectionBodyComp from '../../common/default-section-components/DefaultSectionBodyComp'
import { IMAGE_URL } from '../../../../config/config'

const SailentFeaturesComp = () => {
  return (
    <>
        <DefaultSectionComp className="bg-light border-top border-bottom">
            <div className='col-12'>
                <DefaultSectionHeadingComp className="text-center position-relative">
                    <h2>Sailent Features</h2>
                    <p>Vnext Products come with unmatched quality, aesthetics, style, & durability</p>
                </DefaultSectionHeadingComp>
                <DefaultSectionBodyComp>
                <div className="row align-items-center justify-content-center g-0">
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/fire-resistant.jpg'} className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/termite-resistant.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/water-resistant.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/weather-resistant.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/environment-friendly.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/energy-efficient.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/easy-workability.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 col-xxl-1 px-1"><img src={IMAGE_URL+'images/features/durable.jpg'}  className="w-100 mx-auto d-block" alt=""/></div>
                </div>
                </DefaultSectionBodyComp>
            </div>
        </DefaultSectionComp>
    </>
  )
}

export default SailentFeaturesComp