import React from 'react';
import { Link } from 'react-router-dom';
import productsJson from '../../../assets/data/products.json'
import HeaderComp from '../common/header/HeaderComp';
import FooterComp from '../common/footer/FooterComp';
import { IMAGE_URL } from '../../../config/config';

const DownloadBrochure = () => {
  let products = productsJson.map((p) => {
    return (<div className='col-12 col-md-6 col-xl-4' key={p.key}>
        <div className='product w-100 bg-white shadow rounded-3 overflow-hidden position-relative border'>
            <div className='img py-3 position-relative shadow-sm'><img src={`${IMAGE_URL}${p.product_image}`} className="img-fluid" alt={p.title} /></div>
            <div className='content p-4'>
                <div className='title fw-bold text-muted'>{p.title}</div>
                <Link to={IMAGE_URL+'images/brochure/pdf/vnext-architect-brochure.pdf'} className='btn rounded-1 mt-3' target='_blank'>Download Brochure</Link>
            </div>
        </div>
    </div>);
});
  return (
    <div>
      <HeaderComp/>
         <section className="section light bg-img products-list text-center">
            <div className='container'>
                <div className='products'>
                    <div className='row gy-5'>{products}</div>
                </div>
            </div>
        </section>
        <FooterComp/>
    </div>
  )
}

export default DownloadBrochure