import react from 'react';
import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import { IMAGE_URL } from '../../../../config/config';

const SolutionsDetails = ({ sDetails }) => {
    const convertJSX = htmlData => {
        return htmlData.map((h, hIndex)=>{
            let attributeObj = {};
            const {attributes, localName, childNodes, nodeValue} = h;
            if(attributes){
                Array.from(attributes).forEach(attribute => {
                    if(attribute.name === "style"){
                        let styleAttributes = attribute.nodeValue.split(";");
                        let styleObj = {};
                        styleAttributes.forEach(attribute => {
                            let [key, value] = attribute.split(":");
                            styleObj[key] = value;
                        });
                        attributeObj[attribute.name] = styleObj;
                    }
                    else if(attribute.name === 'class'){ attributeObj['className'] = attribute.nodeValue; }
                    else { attributeObj[attribute.name] = attribute.nodeValue; }
                });
            }
            attributeObj['key'] = hIndex;
            return localName ? react.createElement(localName, attributeObj, childNodes && Array.isArray(Array.from(childNodes)) ? convertJSX(Array.from(childNodes)) : [] ) : nodeValue;
        });
    }
    return (<>
        <DefaultSectionComp className="solutions-details">
            <div className='container'>
                <div className="row">
                    <div className="content">
                        <div className="desc lh-lg"><p>{sDetails.content.desc}</p></div>
                        <div className='list'>
                            {sDetails.content.list.map((l, lIndex) => {
                                let descData = new DOMParser().parseFromString(l.desc, 'text/html').body.childNodes;
                                return  <div key={lIndex} className="col-12"><div className='title'><h6 className='mb-3'><strong>{l.title}</strong></h6></div><div className='desc lh-lg'>{convertJSX(Array.from(descData))}</div></div>
                            })}
                        </div>
                        <div className="download-brochure mt-3" style={{ display: sDetails.brochure_link === "" ? 'none' : 'inline-block' }}>
                            <a href={`${IMAGE_URL}${sDetails.brochure_link}`} className="btn yellow position-relative overflow-hidden d-block d-sm-inline-block text-white" rel='noreferrer  ' target='_blank'>Download Brochure</a>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultSectionComp>
    </>)
}

export default SolutionsDetails