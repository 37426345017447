import { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import './CoveragesComp.css';
import HeaderComp from '../../common/header/HeaderComp';
import PageBannerComp from '../../common/page-banner/PageBannerComp';
import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import FooterComp from '../../common/footer/FooterComp';
import CoveragesListComp from './CoveragesListComp';
import CoveragesDetails from './CoveragesDetails';
import PageNotFoundComp from '../../common/404/PageNotFoundComp';
import { useParams } from 'react-router-dom';

import coveragesJson from '../../../../assets/data/coverages.json'
import { IMAGE_URL } from '../../../../config/config';


const CoveragesComp = () => {

    const { paramCId } = useParams();
    const [cId, setCId] = useState('');

    useEffect(()=>{ if(typeof paramCId != 'undefined'){ setCId(paramCId); }else{ setCId(''); } },[paramCId]);

    let pageStatus = true;
    let pageContent = <PageNotFoundComp title={"Page not Found!"} desc={"The page you are trying to access is not found, please try again."} link={"http://localhost:3000"} linkTitle={"Go to Home"} />;
    let bannerDetails = {
        "title": "Coverages",
        "tagline": "Brilliant, Modern and Utilitarian",
        "desc": "Fibre cement boards are being used in some of the most iconic buildings of our time because they save time and money. They are safer and are aesthetically more beautiful.",
        "image": "images/bg.jpg"
    };
    
    if (cId !== '') {
        let cIdStatus = false;
        let cIdDetails = false;
        coveragesJson.map((c) => { if (c.key === cId) { cIdStatus = true; cIdDetails = c; } return true; });
        if (cIdStatus === true) {
            pageContent = <CoveragesDetails cDetails={cIdDetails} />;
            bannerDetails.title = cIdDetails.title;
            bannerDetails.tagline = cIdDetails.tagline;
            bannerDetails.desc = cIdDetails.desc;
            bannerDetails.image = IMAGE_URL + cIdDetails.background_image;
        } else { pageStatus = false; }
    } else { pageContent = <CoveragesListComp coveragesJson={coveragesJson} />; }
    
    return (<>
        <HeaderComp />
        {pageStatus && <PageBannerComp className={'page-banner-comp'} bType="banner" bDetails={bannerDetails} />}
        {pageContent}
        {pageStatus && <DefaultSectionComp className="section bg-img home-section-join-hands" customInlineStyles={{backgroundImage:`url(${IMAGE_URL}images/bg2.jpg)`}}>
            <div className='container pb-5 mb-5'>
                <div className="text-center home-section-join-hands-content pb-5 mb-5">
                    <h2 className='title'>JOIN HANDS IN BUILDING<br />AN <b className='color-green'>ECO-FRIENDLY</b> FUTURE</h2>
                    <p className='desc mb-5 pb-5'><q>SMALL ACTS, WHEN MULTIPLIED BY MILLIONS OF PEOPLE, CAN TRANSFORM THE WORLD.</q></p>
                </div>
            </div>
        </DefaultSectionComp> }
        <FooterComp />
    </>)
}

export default CoveragesComp