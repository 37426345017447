import React, { useState, useEffect } from 'react'

import './SolutionsComp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import HeaderComp from '../../common/header/HeaderComp';
import PageBannerComp from '../../common/page-banner/PageBannerComp';
import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import FooterComp from '../../common/footer/FooterComp';
import SolutionsListComp from './SolutionsListComp';
import SolutionsDetails from './SolutionsDetails';
import PageNotFoundComp from '../../common/404/PageNotFoundComp';
import { useParams } from "react-router-dom";

import solutionsJson from '../../../../assets/data/solutions.json';
import { IMAGE_URL } from '../../../../config/config';

const SolutionsComp = (props) => {
    const { paramSId } = useParams();
    const [sId, setSId] = useState('');
    
    useEffect(() => { if(typeof paramSId != 'undefined'){ setSId(paramSId); }else{ setSId(''); } }, [paramSId]);
  
    let pageStatus = true;
    let pageContent = <PageNotFoundComp title={"Page not Found!"} desc={"The page you are trying to access is not found, please try again."} link={"/"} linkTitle={"Go to Home"} />;
    let bannerDetails = {
        "title": "Our Solutions",
        "tagline": "Brilliant, Modern and Utilitarian",
        "desc": "Fibre cement boards are being used in some of the most iconic buildings of our time because they save time and money. They are safer and are aesthetically more beautiful.",
        "image": IMAGE_URL+"images/bg.jpg"
    };
    
    if(sId !==""){
        let sIdStatus = false;
        let sIdDetails = false;
        solutionsJson.map((s)=>{if(s.key === sId){sIdStatus = true; sIdDetails = s; } return true; });
        if(sIdStatus === true){
            pageContent = <SolutionsDetails sDetails={sIdDetails} />;
            bannerDetails.title = sIdDetails.title;
            bannerDetails.tagline = sIdDetails.tagline;
            bannerDetails.desc = sIdDetails.desc;
            bannerDetails.image = sIdDetails.background_image;
        } else { pageStatus = false; }
    } else { pageContent = <SolutionsListComp solutionsJson={solutionsJson} />; }
    
    return (<>
        <HeaderComp/>
        {pageStatus && <PageBannerComp className={'page-banner-comp'} bType="banner" bDetails={bannerDetails} />}
        {pageContent}
        {pageStatus && <DefaultSectionComp className="section bg-img home-section-join-hands" customInlineStyles={{backgroundImage:`url(${IMAGE_URL}images/bg2.jpg)`}}>
            <div className='container pb-5 mb-5'>
                <div className="text-center home-section-join-hands-content pb-5 mb-5">
                    <h2 className='title'>JOIN HANDS IN BUILDING<br />AN <b className='color-green'>ECO-FRIENDLY</b> FUTURE</h2>
                    <p className='desc mb-5 pb-5'><q>SMALL ACTS, WHEN MULTIPLIED BY MILLIONS OF PEOPLE, CAN TRANSFORM THE WORLD.</q></p>
                </div>
            </div>
        </DefaultSectionComp> }
        <FooterComp/>
    </>)
}

export default SolutionsComp