import React from 'react'
import HeaderComp from '../common/header/HeaderComp'
import FooterComp from '../common/footer/FooterComp'
import { IMAGE_URL } from '../../../config/config'

const Clients = () => {
  return (
    <div>
        <HeaderComp/>
            <section>
                <div className='container'>
                    <div className='row py-5'>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/1.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/2.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/3.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/4.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/5.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/6.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/7.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/8.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/9.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/10.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/11.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/12.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/13.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/14.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/15.jpg'} alt="client-img"/>
                        </div>
                        <div className='col-6 col-md-4 col-lg-2 col-xl-2 client-img border'>
                            <img src={IMAGE_URL+'images/clients/16.png'} alt="client-img"/>
                        </div>


                    </div>
                    
                </div>
            </section>
        <FooterComp/>
    </div>
  )
}

export default Clients