import React from 'react'
import { Link } from 'react-router-dom';

import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import { IMAGE_URL } from '../../../../config/config';

const SolutionsListComp = ({solutionsJson}) => {

    let solutions = solutionsJson.map((s) => {
        return (
            <div className='col-12 col-md-6 col-xl-4' key={s.key}>
                <div className='w-100 bg-white overflow-hidden shadow rounded-3 position-relative'>
                    <div className='image position-relative'>
                        <img src={`${IMAGE_URL}${s.solution_image}`} className="img-fluid" alt='' />
                    </div>
                    <div className='content p-4'>
                        <div className='title fw-bold'>{s.title}</div>
                        <div className='desc mb-2'>{s.desc.substring(0, 120)+'...'}</div>
                    </div>
                    <Link to={'/solutions/'+s.key} className='view-more position-absolute w-100 h-100 d-flex align-items-center justify-content-center top-0 left-0'>
                        <div className='icon rounded-circle d-flex align-items-center justify-content-center'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"/></svg></div>
                    </Link>
                </div>
            </div>
        )
    });

  return (
    <div>
        <DefaultSectionComp className="section light bg-img solutions-list text-center">
            <div className='container'>
                <div className='solutions'>
                    <div className='row gy-5'>{solutions}</div>
                </div>
            </div>
        </DefaultSectionComp>
    </div>
  )
}

export default SolutionsListComp