import React from 'react'
import HeaderComp from '../common/header/HeaderComp'
import FooterComp from '../common/footer/FooterComp'
import { IMAGE_URL } from '../../../config/config'

const Certificates = () => {
  return (
    <>
        <HeaderComp/>
            <div className='certificates py-5'>
                <div className='container'>
                    <div className='row'>
                        <div id="carouselExampleIndicators" className="carousel slide w-50 mx-auto d-block" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={IMAGE_URL+'images/certificates/vboard.png'} className="d-block w-100" alt="..."/>
                                </div>
                                <div className="carousel-item">
                                    <img src={IMAGE_URL+'images/certificates/vdesigner.png'} className="d-block w-100" alt="..."/>
                                </div>
                                <div className="carousel-item">
                                    <img src={IMAGE_URL+'images/certificates/vpanel.png'} className="d-block w-100" alt="..."/>
                                </div>
                                <div className="carousel-item">
                                    <img src={IMAGE_URL+'images/certificates/vplank.png'} className="d-block w-100" alt="..."/>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        <FooterComp/>
    </>
  )
}

export default Certificates